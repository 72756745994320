import {
    ApplicationConfig, enableProdMode,
    ErrorHandler,
} from '@angular/core';
import {FtErrorHandler} from './ft-error-handler.service';
import {provideAnimationsAsync} from '@angular/platform-browser/animations/async';
import {provideRouter, withViewTransitions} from '@angular/router';
import {appRoutes} from './app.routes';

import {environment} from '../environments/environment';
import {
    provideSharedConfig,
    provideI18nConfig,
    provideJwtConfig,
    provideMomentConfig,
    provideMaterialConfig,
} from './config';
import {provideHttpClient, withInterceptorsFromDi} from '@angular/common/http';


if (environment.production) {
    enableProdMode();
}

export const appConfig: ApplicationConfig = {
    providers: [
        provideHttpClient(withInterceptorsFromDi()),
        provideI18nConfig(),
        provideJwtConfig(),
        provideSharedConfig(),
        provideMomentConfig(),
        provideMaterialConfig(),
        {provide: ErrorHandler, useClass: FtErrorHandler},
        provideRouter(appRoutes, withViewTransitions()),
        provideAnimationsAsync(),
    ],
};

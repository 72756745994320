<div
        class="fx-layout-row fx-content-space-between fx-items-stretch fx-gap-4 fx-fill-width"
>
    <!--template list-->
    <div style="width: 20%" class="fx-layout-column fx-content-space-between">
        <mat-toolbar class="h-[46px]">
            <ng-template #searchTemplate><div [formGroup]="filterForm"></div></ng-template>
      <span class="font-semibold text-lg" style="margin-left: -6px">{{
              "TEMPLATE_MODELS" | translate
          }}</span>
            <span class="fx-grow-1"></span>
			<!--<button mat-icon-button (click)="newCategory($event)">
				<mat-icon fontIcon="mdi-plus" fontSet="mdi"></mat-icon>
			</button>-->
            <button
                    mat-icon-button
                    (click)="downloadAllTemplates()"
                    [matTooltip]="'DOWNLOAD_ALL_TEMPLATES' | translate"
            >
                <mat-icon
                        class="word-icon-gradient"
                        fontIcon="mdi-cloud-download"
                        fontSet="mdi"
                ></mat-icon>
            </button>
        </mat-toolbar>
        <div style="height: calc(100vh - 122px); overflow: auto">
            <mat-tree [dataSource]="dataSource" [treeControl]="treeControl">
                <mat-tree-node *matTreeNodeDef="let node" matTreeNodePadding="1">
                    <button disabled mat-icon-button>
                        <mat-icon
                                style="color: #12b2ff"
                                class="word-icon-gradient"
                                fontIcon="mdi-file-word"
                                fontSet="mdi"
                        ></mat-icon>
                    </button>
                    <span
                            (click)="showTemplateModel(node, 'STD')"
                            [ngClass]="{
              'node-name': true,
              selected: node?.name === selectedTemplate?.name
            }"
                            [ngStyle]="{
              fontWeight:
                node?.name === selectedTemplate?.name ? 'bold' : '400',
              cursor: 'pointer'
            }"
                    >{{ node.name }}</span
                    >

                    <span class="fx-grow-1"></span>
                    <button
                            (click)="selectTemplateItem(node)"
                            [matMenuTriggerFor]="tmMenu"
                            mat-icon-button
                    >
                        <mat-icon fontIcon="mdi-dots-vertical" fontSet="mdi"></mat-icon>
                    </button>

                    <mat-menu #tmMenu="matMenu">
                        <button
                                (click)="duplicateTemplateModel(selectedItem)"
                                mat-menu-item
                        >
                            {{ "DUPLICATE" | translate }}
                        </button>
                        <button
                                [disabled]="!filterCategories(selectedItem?.category).length"
                                [matMenuTriggerFor]="catMenu"
                                mat-menu-item
                        >
                            {{ "MOVE_TO" | translate }}
                        </button>
                        <button (click)="deleteTemplateModel(selectedItem)" mat-menu-item>
                            {{ "DELETE" | translate }}
                        </button>
                    </mat-menu>

                    <mat-menu #catMenu="matMenu">
                        @for (cat of filterCategories(selectedItem?.category); track $index) {
                            <button (click)="moveHere(selectedItem, cat)" mat-menu-item>
                                {{ cat }}
                            </button>
                        }
                    </mat-menu>
                </mat-tree-node>
                <mat-tree-node
                        *matTreeNodeDef="let node; when: hasChild"
                        class="ft-tree"
                        [matTreeNodePadding]="0.5"
                        matTreeNodeToggle
                >
                    <button [attr.aria-label]="'toggle ' + node.name" mat-icon-button>
                        <mat-icon
                                class="folder-icon-gradient"
                                [fontIcon]="
                treeControl.isExpanded(node) ? 'mdi-folder-open' : 'mdi-folder'
              "
                                fontSet="mdi"
                        ></mat-icon>
                    </button>
                    {{ node.name }}
                    <span class="fx-grow-1"></span>
                    <button
                            (click)="$event.stopPropagation(); deleteCategory(node.name)"
                            [matTooltip]="'DELETE' | translate"
                            class="action-icon"
                            mat-icon-button
                    >
                        <mat-icon
                                class="delete-icon-gradient"
                                fontIcon="mdi-delete"
                                fontSet="mdi"
                                style="color: #ba1e5c"
                        ></mat-icon>
                    </button>
                    <button
                            (click)="$event.stopPropagation(); editCategory(node)"
                            [matTooltip]="'EDIT' | translate"
                            class="action-icon"
                            mat-icon-button
                    >
                        <mat-icon
                                class="edit-icon-gradient"
                                fontIcon="mdi-pencil"
                                fontSet="mdi"
                                style="color: deepskyblue"
                        ></mat-icon>
                    </button>
                    <button
                            (click)="$event.stopPropagation(); addModel(node.name, 'STD')"
                            [matTooltip]="'ADD_MODEL' | translate"
                            class="action-icon"
                            mat-icon-button
                    >
                        <mat-icon
                                class="add-icon-gradient"
                                fontIcon="mdi-plus"
                                fontSet="mdi"
                        ></mat-icon>
                    </button>
                </mat-tree-node>
            </mat-tree>
        </div>
    </div>

    <!--TemplateContainer-->
    <div class="fx-grow-1 fx-layout-column fx-content-end">
        @if (selectedTemplate) {
            <div
                    class="fx-layout-row-nowrap fx-content-start fx-items-center fx-gap-8 fx-padding-12"
            >
                <mat-form-field style="width: 312px" subscriptSizing="dynamic">
                    <input
                            [(ngModel)]="selectedTemplate.name"
                            [disabled]="selectedTemplate.name === 'default'"
                            matInput
                            placeholder="{{ 'TEMPLATENAME' | translate }}"
                    />
                </mat-form-field>
                @if (cvis) {
                    <mat-form-field subscriptSizing="dynamic">
                        <mat-select
                                [(ngModel)]="selectedTemplate.formTemplate"
                                placeholder="{{ 'FORM' | translate }}"
                        >
                            @for (form of formTemplates; track $index) {
                                <mat-option [value]="form.name">{{ form.label }}</mat-option>
                            }
                        </mat-select>
                    </mat-form-field>
                }
                <mat-form-field subscriptSizing="dynamic">
                    <mat-select
                            [(ngModel)]="selectedTemplate.category"
                            placeholder="{{ 'FAMILY' | translate }}"
                    >
                        @for (cat of categories; track $index) {
                            <mat-option [value]="cat">{{ cat }}</mat-option>
                        }
                    </mat-select>

                    <button (click)="newCategory($event)" mat-icon-button matIconSuffix>
                        <mat-icon fontIcon="mdi-plus" fontSet="mdi"></mat-icon>
                    </button>
                </mat-form-field>
                <mat-slide-toggle [(ngModel)]="selectedTemplate.mine"
                >{{ "PRIVATE" | translate }}
                </mat-slide-toggle>
            </div>

            <div class="fx-grow-1 fx-overflow-auto">
                <div class="fx-fill">
                    <mat-menu #patientDataMenu="matMenu">
                        @for (item of patientVariables; track $index) {
                            <button (click)="insertVariable(item.key)" mat-menu-item>
                                {{ item.label }}
                            </button>
                        }
                    </mat-menu>

                    <mat-menu #examDataMenu="matMenu">
                        @for (item of examVariables; track $index) {
                            <button (click)="insertVariable(item.key)" mat-menu-item>
                                {{ item.label }}
                            </button>
                        }
                    </mat-menu>

                    <button
                            [matMenuTriggerFor]="patientDataMenu"
                            class="o-patient"
                            color="primary"
                            mat-button
                    >
                        Patient
                    </button>
                    <button
                            [matMenuTriggerFor]="examDataMenu"
                            class="o-exam"
                            color="primary"
                            mat-button
                    >
                        Examen
                    </button>
                    @if (cvis && selectedTemplate?.formTemplate) {
                        <button
                                [matMenuTriggerFor]="formDataMenu"
                                class="o-form"
                                color="primary"
                                mat-button
                        >
                            Champs
                        </button>
                        <mat-menu #formDataMenu="matMenu">
                            @for (item of formVariables; track $index) {
                                <button
                                        (click)="addContentControlWithTag(item.key)"
                                        mat-menu-item
                                        [matMenuTriggerFor]="appMenu"
                                        [matMenuTriggerData]="{ data: item }"
                                >
                                    {{ item.label }}
                                </button>
                            }
                        </mat-menu>

                        <mat-menu #appMenu="matMenu">
                            <ng-template matMenuContent let-data="data">
                                @for (field of data?.fields; track $index) {
                                    <button
                                            mat-menu-item
                                            (click)="addContentControlWithTag(data.key + '.' + field.name)"
                                    >
                                        {{ field.label }}
                                    </button>
                                }
                            </ng-template>
                        </mat-menu>
                    }

                    <ft-reporter
                            (editorInitialized)="editorReady($event)"
                            [documentTitle]="selectedTemplate.name"
                            [fileId]="'T_' + selectedTemplate.id"
                            [templateMode]="1"
                            [editable]="true"
                            [templateModel]="selectedTemplate.id"
                            class="fx-grow-1"
                    >
                    </ft-reporter>
                </div>
            </div>
        }
        <div
                class="btn-actions fx-layout-row-nowrap fx-content-end fx-items-center fx-gap-4 fx-height-42"
                style="padding-right: 12px"
        >
            <button (click)="uploadFiles()" color="warn" mat-raised-button>
                {{ "UPLOAD_FILES" | translate }}
            </button>
            <button (click)="saveTemplateModel()" color="primary" mat-raised-button>
                {{ "SAVEMODEL" | translate }}
            </button>
        </div>
    </div>
</div>

import { ErrorHandler, Injectable } from '@angular/core';

@Injectable()
export class FtErrorHandler implements ErrorHandler {
	handleError(error: unknown): void {
		console.error(
			'%c Error caught by FtErrorHandler',
			'font-weight: bold;color: white;background-color: red;border-radius:3px',
			error
		);
	}
}
